import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutMe = () => {
  return (
    <div style={{paddingTop:'4%', paddingBottom:'3%'}}>
      <Container fluid>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              Hi, I'm Andrew Seung. 
              <br /><br />
              I'm a Systems Engineer based out of Southeast Michigan that currently works on active <a href="https://en.wikipedia.org/wiki/Advanced_driver-assistance_systems" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Advanced Driver Assistance System (ADAS)</a> maneuvers and autonomous driving. My background is in embedded systems software engineering, microservices software engineering, and DevSecOps.
              <br /><br />
              I hold a <a href="https://drexel.edu/engineering/academics/graduate-programs/masters/systems-engineering/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Master of Science in Systems Engineering</a> with a minor in <a href="https://drexel.edu/goodwin/academics/graduate-programs/ms-project-management/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Project Management</a> from <a href="https://drexel.edu/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Drexel University</a> and a <a href="https://www.ecs.baylor.edu/index.php?id=960857" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Bachelor of Science in Computer Science</a> from <a href="https://www.baylor.edu/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}> Baylor University</a>.
              <br /><br />
              At Drexel University, I focused on <a href="https://www.nasa.gov/consortium/ModelBasedSystems" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Model Based Systems Engineering (MBSE)</a>, <a href="https://en.wikipedia.org/wiki/Operations_research" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Operations Research</a>, Project Management and the full Systems Engineering Lifecycle. 
              <br /><br />
              While at Baylor University, I focused on <a href="http://cs.ecs.baylor.edu/research/hci/people/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Human-Computer Interaction</a> research. I was part of a team that presented a paper published in <a href="https://dl.acm.org/doi/10.1145/2788940.2794356" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Association for Computing Machinery</a> and won the <a href="https://www.cs.uwyo.edu/~abanic/SUI2015/awards.html" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Best Poster</a> award at the <a href="https://www.cs.uwyo.edu/~abanic/SUI2015/index.html" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Spatial User Interface 2015</a>.
            </h3>
          </Col>
          <Col />
        </Row>
      </Container>
    </div>
  );
};

export default AboutMe;