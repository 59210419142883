import { useEffect } from 'react';
import Error from "../components/Page/Error/Error";

export default function ErrorRoute() {

  useEffect(() => {
    document.title = 'Page Not Found | Andrew Seung';
  }, []);

  return (
    <>
      <Error />
    </>
  );
}