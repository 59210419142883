import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { AiOutlineMail, AiFillLinkedin, AiOutlineGithub, AiOutlineContacts } from 'react-icons/ai/';
var appVersion = require('./AppVersion.js')

const Footer = () => {
  function AppVersion(props) {
    if (window._env_.ENVIRONMENT === 'development') {
      return (
        <Navbar.Brand 
            data-testid="_test-app-version" 
            className='d-none d-sm-block center ms-auto' 
            style={{fontSize: 12}}>
          <div className='navbar-font'>v{appVersion.version}</div>
        </Navbar.Brand>
      )
    } 
    else if (window._env_.ENVIRONMENT === 'local') {
      return (
        <Navbar.Brand 
            data-testid="_test-app-version" 
            className='d-none d-sm-block center ms-auto' 
            style={{fontSize: 12}}>
          <div className='navbar-font'>local</div>
        </Navbar.Brand>
      )
    } else {
      return null
    }
  }

  return (
    <Navbar className="navbar-bottom-background">
      <Container>
        <Navbar.Brand>
          <div className="navbar-font">
            © Andrew Seung {new Date().getUTCFullYear()}
          </div>
        </Navbar.Brand>
        <AppVersion />
        <Nav className="ms-auto">
          <div className="nav-link">
            <p data-tip='email' data-for='email'>
              <a target="_blank" rel="noreferrer" href="mailto:me@andrewseung.com">
              <AiOutlineMail className='email-icon' />
            </a>
            </p>
            <ReactTooltip id='email'>
              Send me an email!
            </ReactTooltip>
          </div>
          <div className="nav-link">
            <p data-tip='resume' data-for='resume'>
              <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/1CCQQH56EWvlJlmkhWkjr7EcXTUUGGOdH/view?usp=sharing">
                <AiOutlineContacts className='resume-icon'/>
              </a>
            </p>
            <ReactTooltip id='resume'>
              Grab my resume!
            </ReactTooltip>
          </div>
          <div className="nav-link">
            <p data-tip='github' data-for='github'>
              <a target="_blank" rel="noreferrer" href="https://github.com/andrewseung">
                <AiOutlineGithub className='github-icon'/>
              </a>
            </p>
            <ReactTooltip id='github'>
              Checkout my GitHub profile!
            </ReactTooltip>
          </div>
          <div className="nav-link">
            <p data-tip='linkedin' data-for='linkedin'>
              <a target="_blank" rel="noreferrer" href="https://linkedin.com/in/andrewseung">
                <AiFillLinkedin className='linkedin-icon' />
              </a>
            </p>
            <ReactTooltip id='linkedin'>
              Look at my LinkedIn profile
            </ReactTooltip>
          </div>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Footer;