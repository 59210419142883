import React from 'react';
import LandingPageImage from '../../assets/LandingPagePicture.png';
import { Parallax } from 'react-parallax';

const LandingImage = () => {
  return (
    <Parallax className='parallax-image' bgImage={LandingPageImage} bgImageAlt='image' strength={100}>
      <div style={{ height: '94vh' }}>
        <h1 className='image-title d-flex align-items-center justify-content-center text-nowrap'>
          A N D R E W // S E U N G
        </h1>
      </div>
    </Parallax>
  );
};

export default LandingImage;