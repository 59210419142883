import LandingImage from "../components/Image/LandingImage";
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { useEffect } from 'react';
import '../app/App.scss';

export default function LandingPageRoute() {

  useEffect(() => {
    document.title = 'Andrew Seung';
  }, []);

  return (
    <>
      <Header />
      <LandingImage />
      <Footer />
    </>
  );
}