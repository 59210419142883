import React from 'react';
import { NavLink } from "react-router-dom";

const Error = () => {
  return (
    <div className='center' style={{ zIndex: 1 }}>
      <h1>Error 404: Oh no! You stumbled on a page that doesn't exist.</h1>
      <br />
      <h2>
        Want to go back to the&nbsp;
        <NavLink style={{textDecoration: 'none'}} to="/">
          homepage
        </NavLink>
        ?
      </h2>
    </div>
  );
};

export default Error;