import SystemsEngineering from "../components/Page/SystemsEngineering/SystemsEngineering";
import SystemsEngineeringImage from "../components/Image/SystemsEngineeringImage";
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { useEffect } from 'react';
import '../app/App.scss';

export default function SystemsEngineeringRoute() {

  useEffect(() => {
    document.title = 'Systems Engineering | Andrew Seung';
  }, []);

  return (
    <>
      <Header />
      <SystemsEngineeringImage />
      <SystemsEngineering />
      <Footer />
    </>
  );
}