import { React, useEffect, useState } from 'react';
import { Button } from '@material-ui/core/';
import { NavLink } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import { FaExchangeAlt } from 'react-icons/fa';
import { Container, Nav, Navbar } from 'react-bootstrap';

const Header = () => {
  
  // eslint-disable-next-line no-unused-vars
  const [lightMode, setLightMode] = useState('light') 
  const localTheme = window.localStorage.getItem('lightMode')
  const savedMode = localTheme === 'dark' ? 'dark' : 'light'

  const toggleTheme = () => {
    if (savedMode === 'light') {
      window.localStorage.setItem('lightMode', 'dark')
      setLightMode('dark')
    } else {
      window.localStorage.setItem('lightMode', 'light')
      setLightMode('light')
    }
  }

  useEffect(() => {
    setLightMode(localTheme)
    if (localTheme === 'light') {
      document.body.classList.add('is_dark')
    }
    else {
      document.body.classList.remove('is_dark')
    }
  },[localTheme])

  return (
    <Navbar className="navbar-top-background" expand="md" fixed="top">
      <Container>
        <LinkContainer to='/' activeClassName=''>
          <Navbar.Brand className='navbar-title-name' style={{textDecoration: 'none'}}>
            Andrew Seung
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink activeclassname='navbar-active' style={{textDecoration: 'none'}} to="/about-me">
              <div className='d-flex align-items-center justify-content-center'> 
                <Button
                  variant="text"
                  style={{ backgroundColor: 'transparent' }}
                  disableRipple>
                  <div className='navbar-font'>
                    About Me
                  </div>
                </Button>
              </div>
            </NavLink>
            <NavLink activeclassname='active' style={{textDecoration: 'none'}} to="/systems-engineering">
            <div className='d-flex align-items-center justify-content-center'> 
                <Button
                  variant="text"
                  style={{ backgroundColor: 'transparent' }}
                  disableRipple>
                  <div className='navbar-font'>
                    Systems Engineering
                  </div>
                </Button>
              </div>
            </NavLink>
            <div className='inactive d-flex align-items-center justify-content-center'>
              <Button 
              data-testid='_test-color-picker-button'
              onClick={toggleTheme}
              variant="text"
              disableRipple
              style={{ backgroundColor: 'transparent'}}>
                  <FaExchangeAlt className='color-picker-icon'/>
                  <div className='color-picker-font'>&nbsp;Theme</div> 
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;