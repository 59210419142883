import { React, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Outlet, Routes, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import SystemsEngineeringRoute from '../routes/systems-engineering-route'
import AboutRoute from '../routes/about-route';
import ErrorRoute from '../routes/error-route';
import LandingPageRoute from '../routes/landing-page-route';

const App = () => {
  function DevelopmentBuildAlert(props) {
    const [show, setShow] = useState(true)

    if (window._env_.ENVIRONMENT === 'development') {
      return (
        <div className="position-fixed top-50 start-50 translate-middle" style={{ zIndex: 2 }}>
          <Alert show={show} variant="danger" data-testid='_test-pop-up-alert'>
            <Alert.Heading>You're viewing Andrew Seung's development build!</Alert.Heading>
            <hr />
            <p>
              So bugs and issues will be expected. You're more than welcome to browse around. If you would like to view the production website, please head on over to <Alert.Link href="https://andrewseung.com">https://andrewseung.com</Alert.Link>.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <LinkContainer to='/error-page'>
                <Button data-testid='_test-error-page-on-click' onClick={() => setShow(false)} variant="outline-dark">
                Test Error Page!
                </Button>
              </LinkContainer>
              &nbsp;
              <Button data-testid='_test-let-me-browse-on-click' onClick={() => setShow(false)} variant="outline-dark">
                Let me browse!
              </Button>
            </div>
          </Alert>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div style={{ minHeight: '100%', display: 'grid', gridTemplateRows: 'auto 1fr auto', gridTemplateColumns: '100%' }}>
      <DevelopmentBuildAlert />
      <Routes>
        <Route path='/' element={<LandingPageRoute />} />
        <Route path='/about-me' element={<AboutRoute />} />
        <Route path='/systems-engineering' element={<SystemsEngineeringRoute />} />
        <Route path='*' element={<ErrorRoute />} />
      </Routes>
      <Outlet />
    </div>
  );
};

export default App;