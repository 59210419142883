import React from 'react';
import AboutImagePicture from '../../assets/GrandHotelFlag.jpeg';
import { Parallax } from 'react-parallax';

const AboutImage = () => {
  return (
    <Parallax className='parallax-image' bgImage={AboutImagePicture} bgImageAlt='about-me-image' strength={100}>
      <div style={{ height: 300 }}>
        <h1 className='image-title d-flex align-items-center justify-content-center text-nowrap'>
          About Me
        </h1>
      </div>
    </Parallax>
  );
};

export default AboutImage;