import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SystemsEngineering = () => {
  return (
    <div style={{paddingTop:'4%', paddingBottom:'3%'}}>
      <Container fluid>
        <Row>
          <Col />
          <Col xs={9}>
            <h3>
              After I graduated with my <a href="https://www.ecs.baylor.edu/index.php?id=960857" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Bachelor of Science in Computer Science</a> from <a href="https://www.baylor.edu/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}> Baylor University</a>, I started my career at <a href="https://lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics.html" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Lockheed Martin Aeronautics</a> as an embedded software engineer. I worked on the <a href="https://en.wikipedia.org/wiki/Multi-function_display" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Multi-functional Display System (MFDs)</a> for the <a href="https://en.wikipedia.org/wiki/General_Dynamics_F-16_Fighting_Falcon" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>F-16 Fighting Falcon</a> fighter aircraft. From that experience, I gained an interest in understanding how complex systems, like a fighter aircraft, can be engineered, produced, continuously supported, and integrated with the various system of systems. I then moved to the <a href="https://en.wikipedia.org/wiki/Lockheed_Martin_F-22_Raptor" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>F-22 Raptor</a> program, where I focused on <a href="https://www.redhat.com/en/topics/devops/what-is-ci-cd" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>continuous integration and continuous deployment (CI/CD)</a> for middleware. I was then given the opportunity to work on the software integration and build operations organization for the <a href="https://en.wikipedia.org/wiki/Lockheed_Martin_F-35_Lightning_II" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>F-35 Lightning II</a>. My role there was to enhance how software is developed, tested, and delivered to target platforms in an automated fashion. 
              <br /><br />
              While at <a href="https://lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics.html" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Lockheed Martin Aeronautics</a>, I wanted to further my understanding of how systems work as a whole. I started courses and obtained my <a href="https://drexel.edu/engineering/academics/graduate-programs/masters/systems-engineering/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Master of Science in Systems Engineering</a> with a minor in <a href="https://drexel.edu/goodwin/academics/graduate-programs/ms-project-management/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Project Management</a> from <a href="https://drexel.edu/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Drexel University</a>.
              <br /><br />
              The capstone project for my <a href="https://drexel.edu/engineering/academics/graduate-programs/masters/systems-engineering/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Master of Science in Systems Engineering</a> was to create an enhanced <a href="https://azure.microsoft.com/en-us/overview/internet-of-things-iot/what-is-the-internet-of-things/#overview" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>Internet of Things (IoT)</a> ecosystem for home usage. In this system, commercially available solutions are integrated with a custom platform to leverage production ready functionality to give customers a solution that integrated popular services, rather than having to choose and technology lock a customer (e.g. iOS vs Andriod; Google Home vs Amazon Alexa). Some of the theorized functionalities for this system was automated windows operations, a custom voice assistant, and automated home operations to increase energy savings.
              <br /><br />
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              Take a look at some of the artifacts I produced for this system:
            </h3>
          </Col>
          <Col />
        </Row>
        <br />
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              1. Needs Analysis: <a href="https://drive.google.com/file/d/1bRR4z0gigQjLG5gZ_Q-Wbi6RfH7OIPkb/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              2. Operational Requirements Document (ORD): <a href="https://drive.google.com/file/d/1rVRGEpdqCw0M1znRpl_o4xz--zvvt67N/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              3. Concept of Operations (CONOPS): <a href="https://drive.google.com/file/d/1YtI985QoFMlh0EzkvLO3Zyfsfcn8Mf1B/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              4. Risk Management Plan: <a href="https://drive.google.com/file/d/1MmLMz9TTzoxBlwB2oBwchCVZXCaDlWCL/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              5. Verification and Validation Plan: <a href="https://drive.google.com/file/d/1e7cs2UZDXdWlms2A4-Z5NTXMxiZIaHez/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              6. Department of Defense Architecture Framework (DoDAF): <a href="https://drive.google.com/file/d/1S0btzA5dpX2AVd4FP9i5si90guL5_NJ3/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              7. Integrated Logistics Support Plan (ILSP): <a href="https://drive.google.com/file/d/139Xkwbj33AhGMIL0ixdHIq4Dao3Q6eF2/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              7.1 Maintenance Task Analysis (MTA): <a href="https://drive.google.com/file/d/1PSct8T7bJmj5RtUUUgRAuMwrLKIb2RIc/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              7.2 Maintenance Cost Analysis (MCA): <a href="https://drive.google.com/file/d/1xm28XSoMqASdzIYXPi_ii3TnVoGHK7d8/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col xsoffset={2} xs={9}>
            <h3>
              7.3 Life-Cycle Cost Analysis (LCCA): <a href="https://drive.google.com/file/d/1HtB4gDBLprMsLb5kCtXI3YTf5dvxTAeC/view?usp=sharing" target="_blank" rel="noreferrer">link</a>
            </h3>
          </Col>
          <Col />
        </Row>
      </Container>
    </div>
  );
};

export default SystemsEngineering;