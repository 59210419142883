import AboutMe from "../components/Page/AboutMe/AboutMe";
import AboutImage from "../components/Image/AboutImage";
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { useEffect } from 'react';
import '../app/App.scss';

export default function AboutRoute() {
  
  useEffect(() => {
    document.title = 'About Me | Andrew Seung';
  }, []);

  return (
    <>
      <Header />
      <AboutImage />
      <AboutMe />
      <Footer />
    </>
  );
}